import Link from 'next/link'

import { FollowButtonIcon } from '@common/FollowButton'
import UrlHelper from '@lib/UrlHelper'
import { useToggleNodeFollow } from '@pages/node/hooks/useToggleNodeFollow'
import nodeApi from '@redux/api/nodeApi'

export const NodePillLoading = () => <span className="mb-1 mr-1 h-9 w-24 animate-pulse rounded-lg bg-contrast/10" />

const NodePill = ({ slug, className }: { slug: string; className?: string }) => {
  const { data: node, isLoading } = nodeApi.useGetNodeDetailsQuery({ slug })
  const handleFollow = useToggleNodeFollow(node)

  const handleFollowClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleFollow().catch(() => {})
  }

  if (isLoading || !node) return <NodePillLoading />

  const followingClassNames = 'bg-accent/10 text-accent'
  const notFollowingClassNames = 'bg-contrast/10 text-primary'

  return (
    <Link
      className={`btn btn-link mb-1 mr-1 rounded-lg px-0 ${className} flex shrink-0 items-center space-x-1.5 px-2.5 py-2 text-xs font-medium transition-colors duration-200 ${node.isFollowing ? followingClassNames : notFollowingClassNames}`}
      href={UrlHelper.nodePath(slug)}
    >
      <span className="text-primary/50">#</span>
      <span>{node.name}</span>
      <div
        className="-m-1 flex size-[14px] cursor-pointer self-center hover:scale-105 hover:opacity-80"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <FollowButtonIcon
          isFollowing={node.isFollowing ?? false}
          onFollow={handleFollowClick}
          deeplinkUrl={UrlHelper.nodePermalink(node.slug)}
          className={node.isFollowing ? 'text-accent' : 'text-primary/50'}
          source="mobile-url-profile-node-pill"
        />
      </div>
    </Link>
  )
}

export default NodePill
